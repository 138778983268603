export const seo = {
  url: 'wspolpraca',
  title: {
    pl: 'Kontenery Morskie | Współpraca',
    en: 'Cooperation with a trustworthy transport company',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: 'Start cooperation in the field of transport and logistics with an experienced TSL company. Specialized services, dedicated transport and customization.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Współpraca',
    en: 'Collaboration',
  },
  desc: {
    pl: `Rozpocznij współpracę w zakresie sprzedaży i transportu kontenerów morskich. Stawiamy na doświadczoną kadrę i szybką realizację.`,
    en: `Start cooperation in the field of transport and logistics with an experienced TSL company. Specialized services, dedicated transport and customization.`,
  },
}

export const main = {
  title: {
    pl: 'Współpraca z dystrybutorem kontenerów',
    en: 'Cooperation with a trustworthy transport company',
  },
  texts: [
    {
      pl: 'Wybierz szybką realizację oraz dużą dostępność kontenerów morskich w Polsce i Europie.',
      en: `<span><strong>Improve the company's operations</strong> thanks to cooperation with Omida Logistics - the top TSL brand in Europe. Start cooperation in the field of logistics and transport. We focus on experience, dedicated transport services and <strong>individual approach</strong> to the partner's requirements.</span>`,
    }
  ],
}
